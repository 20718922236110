<template>
  <div class="card">
    <el-form
      @submit.prevent="addContractTemplate()"
      :model="contractTemplateNameForm"
      :rules="contractTemplateNameRules"
      ref="contractTemplateNameFormRef"
      class="form"
    >
      <div class="card-header border-0 pt-6">
        <div class="d-flex align-items-center">
          <!-- <label class="mb-2">
            <span class="required fs-6">合約樣板名稱 :</span>
          </label> -->
          <div class="title">合約樣板名稱 :</div>
          <el-form-item class="m-0 ms-2" prop="name">
            <el-input
              class="title"
              v-model="contractTemplateNameForm.name"
            ></el-input>
          </el-form-item>
          <div class="title">備註 :</div>
          <el-form-item class="m-0 ms-2" prop="remark">
            <el-input
              class="title"
              type="textarea"
              v-model="contractTemplateNameForm.remark"
            ></el-input>
          </el-form-item>
        </div>
        <button type="submit" class="btn btn-primary">新增樣板</button>
      </div>
    </el-form>

    <div class="card-body">
      <div class="mt-5 aboveTable">
        <button
          data-bs-toggle="modal"
          data-bs-target="#addContractTemplateItem-modal"
          type="button"
          class="btn btn-success"
        >
          加入優惠項目
        </button>
        <button
          data-bs-toggle="modal"
          data-bs-target="#adjustItemWeight-modal"
          type="button"
          class="btn btn-warning ms-3"
        >
          調整優惠權重
        </button>
      </div>

      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="false"
        :openTextEnd="false"
        :striped="true"
      >
        <template v-slot:cell-name="{ row: tableData }">
          {{ tableData.name }}
        </template>
        <template v-slot:cell-ruleMode="{ row: tableData }">
          {{ tableData.ruleMode }}
        </template>
        <template v-slot:cell-ruleJsons="{ row: tableData }">
          <div v-for="(ruleJson, index) in tableData.ruleJsons" :key="index">
            {{ ruleJson }}
          </div>
        </template>
        <template v-slot:cell-beginTime="{ row: tableData }">
          {{ tableData.beginTime }}
        </template>
        <template v-slot:cell-endTime="{ row: tableData }">
          {{ tableData.endTime }}
        </template>
        <template v-slot:cell-operate="{ row: tableData }">
          <div @click="deleteItem(tableData)" class="deleteText">刪除</div>
        </template>
      </Datatable>
    </div>
  </div>
  <!-- 新增樣板項目modal -->
  <div
    class="modal fade"
    id="addContractTemplateItem-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            id="addContractTemplateItem-exampleModalLabel"
          >
            新增樣板項目
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <AddContractTemplateItem
            @onAddContractTemplateItem="onAddContractTemplateItem($event)"
          ></AddContractTemplateItem>
        </div>
      </div>
    </div>
  </div>
  <!-- 調整規則模式權重modal -->
  <div
    class="modal fade"
    id="adjustItemWeight-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="adjustItemWeight-exampleModalLabel">
            調整規則模式權重
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <AdjustItemWeightByAddContract
            :addContractTemplateDiscountList="addContractTemplateDiscountList"
            @changeRuleModes="changeRuleModes($event)"
          ></AdjustItemWeightByAddContract>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import AddContractTemplateItem from "./components/AddContractTemplateItem.vue";
import AdjustItemWeightByAddContract from "./components/AdjustItemWeightByAddContract.vue";
import { Modal } from "bootstrap";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import { discountRuleModeMap } from "@/core/services/responsesModel/contract";
import { getFacilityBySpace } from "@/core/services/api/facilities";
import { addContract } from "@/core/services/api/contract";
import {
  FacilityExtend,
  FacilityType,
} from "@/core/services/responsesModel/facilityExtend";
import localStorage from "@/core/services/LocalstorageService";
import {
  AddContractTemplateDiscountListModel,
  AddContractTemplateItemForm,
} from "./model";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter, useRoute } from "vue-router";

// 列表資料Model
interface tableDataModel {
  id: string;
  name: string;
  ruleMode: string;
  ruleJsons: Array<string>;
  beginTime: string;
  endTime: string;
}

export default defineComponent({
  components: {
    Datatable,
    AddContractTemplateItem,
    AdjustItemWeightByAddContract,
  },
  setup() {
    // 樣板名稱
    const contractName = ref<string>("");
    const router = useRouter();
    // contractTemplateNameformRef
    const contractTemplateNameFormRef = ref<null | HTMLFormElement>(null);
    // 合約樣板名稱表單
    const contractTemplateNameForm = reactive({
      name: "",
      remark: ""
    });
    const requestRuleModes: any = reactive([]);
    // 合約樣板名稱驗證規則
    const contractTemplateNameRules = {
      name: [
        {
          required: true,
          message: "請填寫合約樣板名稱",
          trigger: "blur",
        },
      ],
    };
    // 設施列表
    const facilities: Array<FacilityExtend> = reactive([]);
    // 空間guid
    const spaceGuid = localStorage.getCurrentSpaces()?.space.guid;
    // 新增合約樣板優惠列表
    const addContractTemplateDiscountList: Array<AddContractTemplateDiscountListModel> =
      reactive([]);
    // 新增合約優惠項目視窗
    let addContractTemplateItemModal;
    // 列表資料
    const tableData = reactive<Array<tableDataModel>>([]);
    // 列表標題
    const tableHeader = [
      { key: "name", name: "名稱", sortable: true },
      { key: "ruleMode", name: "規則模式", sortable: true },
      { key: "ruleJsons", name: "參數", sortable: true },
      { key: "beginTime", name: "起始時間", sortable: true },
      { key: "endTime", name: "結束時間", sortable: true },
      { key: "operate", name: "操作" },
    ];

    // 添加優惠項目
    const addItem = (addForm: AddContractTemplateItemForm) => {
      let newData: AddContractTemplateDiscountListModel = {
        id: Math.floor(Math.random() * new Date().getTime()).toString(),
        ruleMode: addForm.ruleMode,
        ruleJson: addForm.ruleJson,
        beginTime: addForm.beginTime,
        endTime: addForm.endTime,
      };
      addContractTemplateDiscountList.push(newData);
    };

    // 刪除優惠項目
    const deleteItem = (deleteItem: tableDataModel) => {
      let filterList = addContractTemplateDiscountList.filter(
        (item) => item.id !== deleteItem.id
      );
      addContractTemplateDiscountList.splice(
        0,
        addContractTemplateDiscountList.length,
        ...filterList
      );
    };

    // 新增樣板項目事件
    const onAddContractTemplateItem = (
      addForm: AddContractTemplateItemForm
    ) => {
      addContractTemplateItemModal.hide();
      addItem(addForm);
    };

    // 取得空間設施
    const handleGetFacilityBySpace = async () => {
      return (await getFacilityBySpace(spaceGuid!)).filter(
        (facility) => facility.facility.type == FacilityType.Facility
      );
    };

    // 根據設施唯一識別查詢設施名稱
    const getFacilityNameByGuid = (guid) => {
      let filterFacilities = facilities.filter(
        (facility) => facility.facility.guid == guid
      );
      return filterFacilities[0].facility.name;
    };

    // 取得複選設施顯示參數
    const getCheckFacilitiesShowRuleJsons = (targets: Array<string>) => {
      let show = "";
      targets.forEach((target) => {
        show = show + `${getFacilityNameByGuid(target)},`;
      });
      show = show.substr(0, show.length - 1);
      return show;
    };

    // 取得顯示參數
    const getShowRuleJson = (
      addContractTemplateDiscount: AddContractTemplateDiscountListModel
    ) => {
      let showRuleJson: Array<string> = [];
      const ruleJson: any = JSON.parse(addContractTemplateDiscount.ruleJson);
      delete ruleJson.name;
      let showTarget = "指定設施:";
      switch (addContractTemplateDiscount.ruleMode) {
        // 全額折抵
        case "FullAmount":
          showRuleJson.push("全額折抵");
          break;
        // 預付金額折抵
        case "PrepaidAmount":
          showRuleJson.push(`預付金額:${ruleJson.maxDiscountAmount}`);
          break;
        // 指定設施全額折抵
        case "TargetFullAmount":
          showRuleJson.push(
            `指定設施:${getCheckFacilitiesShowRuleJsons(ruleJson.targets)}`
          );
          break;
        // 指定設施時間單位折抵
        case "TargetTimespan":
          showRuleJson.push(
            `指定設施:${getCheckFacilitiesShowRuleJsons(ruleJson.targets)}`
          );
          showRuleJson.push(`時間單位:${ruleJson.maxDiscountTimeSpan}`);
          break;
        // 指定設施特惠價格
        case "TargetSpecialTimespanAmount":
          showRuleJson.push(
            `指定設施:${getFacilityNameByGuid(ruleJson.target)}`
          );
          showRuleJson.push(`特惠價格:${ruleJson.specialTimespanAmount}元`);
          break;
        // 指定設施折數優惠
        case "TargetsPercent":
          showRuleJson.push(
            `指定設施:${getCheckFacilitiesShowRuleJsons(ruleJson.targets)}`
          );
          showRuleJson.push(`折數優惠:${ruleJson.percent * 100}折`);
          break;
      }

      return showRuleJson;
    };

    // 設置資料表
    const setTableData = () => {
      tableData.splice(0);
      for (const addContractTemplateDiscount of addContractTemplateDiscountList) {
        tableData.push({
          id: addContractTemplateDiscount.id,
          name: JSON.parse(addContractTemplateDiscount.ruleJson).name,
          ruleMode: discountRuleModeMap[addContractTemplateDiscount.ruleMode],
          ruleJsons: getShowRuleJson(addContractTemplateDiscount),
          beginTime: getLocalTimeString(addContractTemplateDiscount.beginTime),
          endTime: getLocalTimeString(addContractTemplateDiscount.endTime),
        });
      }
    };

    // 新增合約樣板
    const addContractTemplate = () => {
      if (!contractTemplateNameFormRef.value) {
        return;
      }
      contractTemplateNameFormRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "確定要新增合約樣板嗎?",
            icon: "warning",
            text: "新增後將無法刪除!",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `確定`,
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request = {
                contractBasicData: {
                  name: contractTemplateNameForm.name,
                  remark: contractTemplateNameForm.remark,
                  discountPriority: {
                    discountRuleModes: requestRuleModes,
                  },
                },
                contractDiscountsBasicData: addContractTemplateDiscountList,
              };
              await addContract(spaceGuid, request);
              await Swal.fire("新增成功!", "", "success");
              router.go(-1)
            }
          });
        }
      });
    };

    const changeRuleModes = (ruleModes) => {
      console.log("ruleModes", ruleModes.value);
      requestRuleModes.splice(0, requestRuleModes.length, ...ruleModes.value);
    };

    watch(
      addContractTemplateDiscountList,
      () => {
        setTableData();
      },
      {
        deep: true,
        immediate: true,
      }
    );

    onMounted(() => {
      addContractTemplateItemModal = new Modal(
        document.getElementById("addContractTemplateItem-modal")
      );
    });

    const init = async () => {
      facilities.splice(
        0,
        facilities.length,
        ...(await handleGetFacilityBySpace())
      );
    };
    init();

    return {
      contractName,
      tableHeader,
      tableData,
      addItem,
      deleteItem,
      onAddContractTemplateItem,
      contractTemplateNameFormRef,
      contractTemplateNameForm,
      contractTemplateNameRules,
      addContractTemplate,
      addContractTemplateDiscountList,
      changeRuleModes,
    };
  },
});
</script>

<style scoped>
.title {
  font-size: 22px;
}
.itemsCenter {
  display: flex;
  align-items: center;
}
.deleteText {
  color: rgb(235, 3, 3);
}

.deleteText:hover {
  color: rgb(189, 0, 0);
  cursor: pointer;
}
</style>